export default {
  en: {
    error: {
      user: {
        mobileNumberAlreadyInUse: 'The Mobile %{0} is already in use.',
        mobileNumberNotDifferent: 'The Mobile %{0} has not changed.',
        alreadyActivated: 'The User is already activated.',
        emailNotDifferent: 'The Email %{0} has not changed.',
        emailAlreadyInUse: 'The Email %{0} is already used.',
        userrefAlreadyInUse: 'Something wrong in the keycloak DB userRef %{0}',
        twoUserSameLogin: 'Something wrong in the keycloak DB',
        sameEmailDifferentMobileNumber: 'User exists with this email but has different mobile number.',
        userOnlyInLocalDb: 'User existiert nur in der lokalen DB: userref %{0}',
        sameUserRefDifferentEmail: 'Identical UserRef but has diffrent Email address: %{0}, %{1}',
        userAlreadyConnected: 'User is already created: %{0}, %{1}'
      },
      healthrelation: {
        patientSelfExists: 'The Email %{0} is already used.',
        sameEmailDifferentMobile: 'User exists with this email but has different mobile number.',
        subjectWithSameEmail: 'The Email %{0} is already used.',
        patientActivity: {
          changeNotAllowed: 'You cannot modify this event because it was created by another user'
        }
      },
      survey: {
        study: {
          participantIdExists: 'The Participant-Id %{0} is already used.',
          participantAlreadyInStudy: 'Participant is already in the study %{1}.',
          studyHasParticipants: "You can't edit the schedule because the Study already has Participants.",
          statusForbidsNewParticipant: "You can't add new participant to the study currently.",
          studyDoctorHasParticipants: "You can't remove this physician, he/she already has added patients",
          patientNotInStudy: 'The Survey can only be sent to study patients.'
        },
        spec: {
          unreadable: {
            'unsupported-elements':
              'Unsupported element %{0}, the Survey can support only this elements: "boolean", "checkbox", "comment",' +
              ' "dropdown", "html", "imagepicker", "matrix", "matrixdropdown", "multipletext",' +
              ' "radiogroup", "rating", "text"',
            'unsupported-elements-text':
              'Unsupported element in JSON, the Survey can support only this elements: "boolean",' +
              '"checkbox", "comment",' +
              ' "dropdown", "html", "imagepicker", "matrix", "matrixdropdown", "multipletext",' +
              ' "radiogroup", "rating", "text"',
            'duplicate-names': 'You have duplicated name: %{0}'
          }
        },
        themeIdAssociatedWithSurvey: 'Not deleted as theme is assosiated with survey(s).'
      },
      messaging: {
        subscriptionAlreadyExists: 'User is already part of the Conversation.',
        subscriptionDeleteOnlyOwn: 'Members of this conversation can remove only themselves'
      }
    }
  },
  de: {
    error: {
      user: {
        mobileNumberAlreadyInUse: 'Die Mobiltelefon-Nummer %{0} wird bereits verwendet',
        emailAlreadyInUse: 'Die Email %{0} wird bereits verwendet.',
        alreadyActivated: 'Der Benutzer wurde bereits aktiviert.',
        userrefAlreadyInUse: 'Es ist etwas schief gegangen in der keycloak DB: userRef %{0}.',
        twoUserSameLogin: 'Es ist etwas schief gegangen in der keycloak DB.',
        sameEmailDifferentMobileNumber:
          'Ein Benutzer mit dieser Email Adresse existiert bereits, hat aber eine andere Telefonnummer.',
        userOnlyInLocalDb: 'Benutzer existiert nur in der Lokalen DB: userref %{0}.',
        sameUserRefDifferentEmail: 'Identische UserRef aber andere Email: %{0}, %{1}.',
        userAlreadyConnected: 'User ist bereits vollständig erfasst: %{0}, %{1}.'
      },
      healthrelation: {
        patientSelfExists: 'Die Email %{0} wird bereits verwendet.',
        sameEmailDifferentMobile:
          'Ein Benutzer mit dieser Email Adresse existiert bereits, hat aber eine andere Telefonnummer.',
        subjectWithSameEmail: 'Die Email %{0} wird bereits verwendet.',
        patientActivity: {
          changeNotAllowed: 'Sie können dieses Ereignis nicht ändern, da es von einem anderen Benutzer erstellt wurde'
        }
      },
      survey: {
        study: {
          participantIdExists: 'Die Teilnehmer-Id %{0} wurde bereits vergeben.',
          participantAlreadyInStudy: 'Der Teilnehmer wurde der Studie %{1} bereits hinzugefügt.',
          studyHasParticipants: 'Sie können den Zeitplan nicht bearbeiten, weil die Studie bereits Teilnehmer hat.',
          statusForbidsNewParticipant: 'Der Studie können aktuell keine neuen Teilnehmer hinzugefügt werden.',
          studyDoctorHasParticipants:
            'Sie können diesen Arzt nicht entfernen, er/sie hat bereits Patienten hinzugefügt.',
          patientNotInStudy: 'Die Umfrage kann nur an Studienpatienten versendet werden.'
        },
        spec: {
          unreadable: {
            'unsupported-elements':
              'Unsupported element %{0}, the Survey can support only this elements: "boolean", "checkbox", "comment",' +
              ' "dropdown", "html", "imagepicker", "matrix", "matrixdropdown", "multipletext",' +
              ' "radiogroup", "rating", "text"',
            'unsupported-elements-text':
              'Unsupported element in JSON, the Survey can support only this elements:' +
              '"boolean", "checkbox", "comment",' +
              ' "dropdown", "html", "imagepicker", "matrix", "matrixdropdown", "multipletext",' +
              ' "radiogroup", "rating", "text"',
            'duplicate-names': 'You have duplicated name: %{0}'
          }
        },
        themeIdAssociatedWithSurvey: 'Nicht gelöscht, da das Thema mit Umfrage(n) verknüpft ist.'
      },
      messaging: {
        subscriptionAlreadyExists: 'Benutzer wurder der Konversation bereits hinzugefügt.',
        subscriptionDeleteOnlyOwn: 'Mitglieder dieser Konversation können nur sich selber entfernen.'
      }
    }
  }
}
