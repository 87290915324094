// @flow
import invariant from 'invariant'

export const surveysActionTypes = {
  SET_SEND_SURVEY_DIALOG: 'SET_SEND_SURVEY_DIALOG',
  TOGGLE_SURVEY_RESPONSES_DIALOG: 'TOGGLE_SURVEY_RESPONSES_DIALOG',
  TOGGLE_SHOW_SCORE_CHART: 'TOGGLE_SHOW_SCORE_CHART',
  EXPORT_SURVEY_CSV: 'EXPORT_SURVEY_CSV',
  EXPORT_SURVEY_JSON: 'EXPORT_SURVEY_JSON',
  UPLOAD_SURVEY_THEMES_SUCCEEDED: 'UPLOAD_SURVEY_THEMES_SUCCEEDED',
  UPLOAD_SURVEY_THEMES_FAILED: 'UPLOAD_SURVEY_THEMES_FAILED',
  DELETE_SURVEY_THEMES_SUCCEEDED: 'DELETE_SURVEY_THEMES_SUCCEEDED',
  DELETE_SURVEY_THEMES_FAILED: 'DELETE_SURVEY_THEMES_FAILED',
  SHOW_SURVEY_THEME_UPLOAD_ERROR: 'SHOW_SURVEY_THEME_UPLOAD_ERROR',
  TOGGLE_SURVEY_GROUP_DIALOG: 'TOGGLE_SURVEY_GROUP_DIALOG',
  REMOVE_STUDY_SURVEY_FAILED: 'REMOVE_STUDY_SURVEY_FAILED',
  DELETE_SURVEY_THEME_FAILED: 'DELETE_SURVEY_THEME_FAILED'
}
export type SurveysActionType = $Keys<typeof surveysActionTypes>
export const surveysActions = {
  setSendSurveyDialog(show: boolean) {
    return {
      type: surveysActionTypes.SET_SEND_SURVEY_DIALOG,
      payload: show
    }
  },
  toggleSurveyResponsesDialog(participant: ?number) {
    invariant(!(isNaN(participant) && participant !== undefined), 'participant must be a number or undefined')
    return {
      type: surveysActionTypes.TOGGLE_SURVEY_RESPONSES_DIALOG,
      payload: participant
    }
  },
  toggleShowScoreChart(surveyId: number) {
    return {
      type: surveysActionTypes.TOGGLE_SHOW_SCORE_CHART,
      payload: surveyId
    }
  },
  exportSurveyCSV(surveyId: number) {
    return {
      type: surveysActionTypes.EXPORT_SURVEY_CSV,
      payload: { surveyId }
    }
  },
  exportSurveyJSON(surveyId: number) {
    return {
      type: surveysActionTypes.EXPORT_SURVEY_JSON,
      payload: { surveyId }
    }
  },
  showSurveyThemeUploadError() {
    return {
      type: surveysActionTypes.SHOW_SURVEY_THEME_UPLOAD_ERROR
    }
  },
  showSurveyThemeUploadCommonError() {
    return {
      type: surveysActionTypes.UPLOAD_SURVEY_THEMES_FAILED
    }
  },
  toggleSurveyGroupDialog(payload: ?Object) {
    return {
      type: surveysActionTypes.TOGGLE_SURVEY_GROUP_DIALOG,
      payload
    }
  }
}
